import React from 'react';
import PetIcon from '../assets/svg/PetIcon';
import WeaponIcon from '../assets/svg/WeaponIcon';
import MegaphoneIcon from '../assets/svg/MegaphoneIcon';
import MapIcon from '../assets/svg/MapIcon';
import WidgetIcon from '../assets/svg/WidgetIcon';

type NavigationProps = {
    active: number,
    onClick: (index: number) => void,
    menuIcons?: React.ReactElement[]
}

const NavigationWidget = ({ active, onClick, menuIcons }: NavigationProps) => {
    let menu: React.ReactElement[] = [<WidgetIcon />, <PetIcon />, <WeaponIcon />, <MapIcon />, <MegaphoneIcon />]
    if (menuIcons) {
        menu = menuIcons
    }
    return (
        <div className='bg-custom-gray2 w-12 py-5 rounded-full flex justify-center nav-widget absolute left-8'>
            <ul>
                {
                    menu.map((val, index) => {
                        return <li className={`flex items-center justify-center ${active === index ? 'active' : ''}`} key={`val-${index}`} onClick={() => onClick(index)}>{val}</li>
                    })
                }
            </ul>
        </div>
    );
};

export default NavigationWidget;