import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Mousewheel, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import NavigationWidget from "../components/NavigationWidget";
import agents from "../assets/img/Agents.png";
import PetIcon2 from "../assets/svg/PetIcon2";
import WidgetIcon from "../assets/svg/WidgetIcon";
import NodesIcon from "../assets/svg/NodesIcon";
import WeaponIcon2 from "../assets/svg/WeaponIcon2";
import PlayIcon from "../assets/svg/PlayIcon";

const About = () => {
  const [active, setActive] = useState(0);
  const swiperRef = useRef();
  return (
    <div className="relative about">
      <NavigationWidget
        active={active}
        onClick={(val) => swiperRef.current.slideTo(val)}
        menuIcons={[
          <WidgetIcon />,
          <PlayIcon />,
          <NodesIcon />,
          <WeaponIcon2 />,
          <PetIcon2 />,
        ]}
      />
      <Swiper
        slidesPerView="1"
        mousewheel={true}
        keyboard={true}
        direction="vertical"
        modules={[Keyboard, Scrollbar, Mousewheel]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(val) => setActive(val.realIndex)}
      >
        <SwiperSlide>
          <div className="relative section">
            <div className="container">
              <div className="absolute left-0 w-full text-left text-container">
                <div className="text-white md:max-w-sm">
                  <h2 className="font-black tracking-widest md:text-5xl text-2xl">
                    INTRODUCTION
                  </h2>
                  <p className="md:leading-8 text-xs tracking-widest font-light">
                    One Tap Is A Free-To-Play First-Person Shooter Designed
                    Specifically To Get The Attention Of Players Who Love
                    Action-Packed Video Games. In It, You Play As One Of The
                    Super Soldiers Known As “Agents” And You Must Defeat Other
                    Agents In A Battle Of Skill And Wits, As You Navigate The
                    Different Arenas And Game Modes Brought Into The Game To
                    Challenge You. As An Agent, You Will Have To Balance Your
                    Shooting And Your Planning Abilities, As You Will Have A Pet
                    That Will Give You Exceptional Abilities With The Potential
                    Of Tipping The Scales In Your Favor If You Use Them At The
                    Right Moment. In Our “Arena Mode”, Players Will Struggle To
                    Survive, Not Only Each Other But The Several Environmental
                    Threats Such As Toxic Gas, Quicksand, Or Ice Traps Or The
                    Deadly Enemies That Plague The Maps Waiting For An Easy
                    Prey.
                  </p>
                </div>
              </div>
              <div
                className="bg-cover bg-center bg-intro absolute"
                style={{ backgroundImage: `url(${agents})` }}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default About;
