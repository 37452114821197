import React, { useState } from 'react';

const MrPoshoFrito = () => {
    const [password, setPassword] = useState('');
    const [placeholder, setPlaceholder] = useState("¿Are you lost?");

    const handleClick = () => {
        setPlaceholder("You should not be here");
    };

    const handleInputChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'white', zIndex: 9999, overflow: "auto" }}>
            {password !== 'MrPoshoFrito' ?
                <div className="containerp3" onClick={handleClick}>
                    <div className="topp3"></div>
                    <div className="bottomp3"></div>
                    <div className="centerp3">
                        <input className='inputp3' type="password" placeholder={placeholder} value={password} onChange={handleInputChange} />
                    </div>
                </div>
                :
                <div style={{ marginTop: 50, display: "flex", alignItems: "center", flexDirection: "column", gap: "50px" }}>
                    <div className='second-overlay' style={{ boxShadow: "0 0 10px rgba(75, 1, 65, 0.877)", width: "250px", height: "250px" }}>
                        Overlay layer 2
                    </div>
                    <div className='third-overlay' style={{ width: "250px", height: "250px" }}>
                        Overlay layer 3
                    </div>
                    <div class="buttonrng">
                        Button rng
                    </div>
                    <div class="buttonrng2">
                        Button rng 2
                    </div>
                    <div style={{ border: "2px solid black" }}>
                        Image Shadow
                        <img
                            className='img-b-shadow'
                            src={require('../assets/img/Logo.png')}
                            alt='Example Shadow'
                            style={{ width: '100%', maxWidth: '400px' }}
                        />
                    </div>
                    <div className='p-overflow'>
                        Overflow:
                        <p>"I am the Bone of my Sword</p>
                        <p>Steel is my Body and Fire is my Blood.</p>
                        <p>I have created over a Thousand Blades,</p>
                        <p>Unknown to Death,</p>
                        <p>Nor known to Life.</p>
                        <p>Have withstood Pain to create many Weapons</p>
                        <p>Yet those Hands will never hold Anything.</p>
                        <p>So, as I Pray--</p>
                        <p>Unlimited Blade Works"</p>
                    </div>
                    <div className='rainbow-text'>
                        Mr. Posho Bross Frito was real... long forgotten by Mr. Posho P. Frito...
                    </div>
                   
                </div>
            }
        </div >
    );
};

export default MrPoshoFrito;