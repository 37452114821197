import * as React from "react";

const Close = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.592" height="24.592" viewBox="0 0 24.592 24.592">
        <g id="Grupo_17979" data-name="Grupo 17979" transform="translate(-1699.695 -1035.695)">
            <path id="Vector" d="M0,19.642,19.642,0" transform="translate(1702.17 1038.17)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5" />
            <path id="Vector-2" data-name="Vector" d="M19.642,19.642,0,0" transform="translate(1702.17 1038.17)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5" />
        </g>
    </svg>

);

export default Close;