import * as React from "react"
import { SVGProps } from "react"
const stonehallaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 383.09 313.11"
    width={props.width ? props.width : 25}
    height={props.height ? props.height : 25}
    {...props}
  >
    <g data-name="Capa 1">
      <path d="M0 77.16c1-4.41 1.83-8.87 3.02-13.23 3.19-11.7 8.54-22.32 16.16-31.81 9.2-11.46 20.29-20.51 33.83-26.3C58.41 3.51 64.04 1.5 69.75.24c4.04-.89 8.1.73 10.69 4.34 2.43 3.39 2.94 7.38 1.1 11.06-1.94 3.88-4.71 7.33-6.91 11.09C66.33 40.9 65 55.9 70.15 71.35c1.82 5.45 4.72 10.53 7.35 16.29-20.01 18.68-35.4 41.35-45.14 68.88-2.48-2.03-5.04-3.63-6.96-5.79-6.38-7.2-12.35-14.71-16.44-23.57-4.13-8.93-7.46-18.03-8.24-27.92-.03-.39-.48-.74-.73-1.11V77.17ZM157.94 154.64V54.75c0-6.71 4.48-11.24 11.18-11.26 14.47-.04 28.93-.08 43.4.01 7.97.05 12.76 4.75 12.76 12.81.04 63.1.12 126.2-.04 189.3-.05 19.78-5.37 38.13-17.32 54.16-2.87 3.84-6.27 7.34-9.75 10.66-3.9 3.73-10.36 3.51-14.22-.33-11.81-11.75-20.15-25.59-23.61-41.96-1.46-6.89-2.24-14.05-2.3-21.09-.24-30.8-.1-61.6-.1-92.41ZM350.52 156.37c-9.99-26.88-24.66-50.07-45.25-68.29 2.77-6.39 5.93-12.14 7.79-18.28 5.19-17.14 2.28-33.16-7.86-47.93-1.95-2.84-4.23-5.88-4.86-9.12-1.36-7.03 4.61-15.14 16.14-11.62 13.81 4.22 26.35 10.49 37.12 20.42 14.41 13.29 23.86 29.18 27.38 48.49.91 5.02 2.18 10.1 2.11 15.14-.18 12.55-1.82 24.96-6.74 36.67-4.98 11.86-12.09 22.3-21.45 31.16-1.2 1.14-2.63 2.04-4.37 3.37ZM136.1 200.67v67.26c-.22.01-1.2.1-2.17.11-33.28 0-66.56 0-99.84-.02-6.21 0-10.86-4.6-10.87-10.77-.03-15.08-.03-30.16-.01-45.24 0-6.93 4.45-11.32 11.48-11.32 30.66-.02 61.32 0 91.99 0h9.42ZM245.69 268.04c.49-3.53 1.22-6.53 1.28-9.55.35-18.52.55-37.05.8-55.57v-2.06c1.09-.07 2.04-.19 2.99-.19h96.14c8.25 0 13.16 4.85 13.15 13.22-.01 13.59-.16 27.19.06 40.78.13 8.23-6.2 13.62-13.41 13.54-32.54-.37-65.09-.16-97.64-.16h-3.37ZM135.28 178.05H49.51c11.75-50.81 49.37-86.55 85.77-100.77v100.77ZM247.97 77.49c20.01 8.3 37.36 20.46 51.78 36.47 16.54 18.37 27.74 39.73 33.6 64.1h-85.38V77.49Z" 
      style={{
        fill: "#b7b7b7",
    }}
      />
    </g>
  </svg>
)
export default stonehallaIcon
