import React, { useEffect } from 'react';

const WombatDownload = () => {
    const imagesTop = [
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Armadillo.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Dragon.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Pteranodon.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Sabertooth.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/snake.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Monkey.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Fox.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Rabbit.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Wolf.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Lion.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/trex.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Cat.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Eagle.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Mouse.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Panter.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Hyena.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/tiger.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/toad.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png",
        "https://qbaby-web-assets.s3.amazonaws.com/dog.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Toxic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/octopus.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v5.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Hagal_Celestial_Swan_Light_Camo.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Lancer_Celestial_Swan_Light_Camo.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v4.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/QuickHammer_Stonehalla.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/QuickHammer_Artic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v3.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Eva_Toxic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg",
    ];
    const imagesBottom = [
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Toxic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Hagal_Celestial_Swan_Light_Camo.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v4.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/tiger.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Wolf.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/snake.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Cat.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v5.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Armadillo.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/QuickHammer_Stonehalla.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Eagle.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Rabbit.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Mouse.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Panter.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/toad.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Dragon.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Pteranodon.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Eva_Toxic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Hyena.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/QuickHammer_Artic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Lancer_Celestial_Swan_Light_Camo.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Lion.png",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Celestial_Camellia_Dark_Basic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Toxic.jpg",
        "https://d2ceeuvjz02d1t.cloudfront.net/one+tap+skins/Crimson_Edge_Toxic.jpg"
    ];
    const shownImagesTop = imagesBottom.concat(imagesTop);
    const shownImagesBottom = imagesTop.concat(imagesBottom);


    useEffect(() => {
        window.open("https://d2ceeuvjz02d1t.cloudfront.net/installer/OneTapLauncher1.exe", "_blank");
    }, []);

    return (
        <div>
            <div class="slider">
                <div class="slide-track">
                    {shownImagesTop.map((imageUrl, index) => (
                        <div key={index} class="slide">
                            <img src={imageUrl} alt="pet" />
                        </div>
                    ))}
                </div>
            </div>
            <div className="message-container" style={{ marginTop: 50, marginBottom: 50, width: "fit-content" }}>
                <p>THE LAUNCHER STARTED DOWNLOADING</p>
                <p>OPEN THE ONETAPLAUNCHER1.EXE TO START THE INSTALLATION</p>
                <p>AND START EARNING AND PLAYING WITH ALL THE FANTASTIC ASSETS</p>
            </div>
            <div class="slider">
                <div class="slide-track">
                    {shownImagesBottom.map((imageUrl, index) => (
                        <div key={index} class="slide">
                            <img src={imageUrl} alt="pet" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WombatDownload;
