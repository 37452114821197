import React from 'react';
import PlaceholderNft from "../assets/img/PlaceholderNft.png"

const NFTCard = ({ nftData, selec }) => {
    const { img, name, tokenType, token_address, token_id, image_url } = nftData;

    const opeDetailsTab = () => {
        const collection =
            token_address === "0xeea2fe42cc1774a802edfc7a08a35c361827befc" ? "egg" :
                token_address === "0x8a2315e441786e10ff1962387407628108b9ba33" ? "skin" :
                    token_address === "0x938b5c1850106ebc82d49af65a030e1d9083f2f5" ? "badge" :
                        token_address === "0x6cc712a73cb45d212f594ef942385ef90141dac0" ? "X" :
                            "unknown";
        if (collection === "egg" || collection === "skin" || collection === "badge") {
            window.open(`/nft-details/${collection}/${token_id}`, '_blank')
        }
    };

    const cardStyle = {
        border: '1px solid #46111E',
        borderRadius: '5px',
        boxShadow: '0 0 5px #46111E',
        padding: '10px',
        color: '#46111E',
        width: '250px',
        height: '300px',
        overflow: 'hidden',
        position: 'relative',
    };

    const imageStyle = {
        width: '100%',
        height: 'auto',
    };

    const checkMarkStyle = {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        fontSize: '24px',
        color: 'green',
    };
    const externalLink = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '24px',
        color: 'white',
        cursor: 'pointer',
    };

    return (
        <div className="black-bg pt-5">
            <div className="container pb-5 pt-5">
                <div className="grid grid-cols-1 gap-4">
                    <div className='text-left p-5'>
                        <div className='bg-box' style={cardStyle}>
                            <div style={externalLink} onClick={opeDetailsTab}>&#129109;</div>
                            <div>
                                <img src={img || image_url || PlaceholderNft} alt={name} style={imageStyle} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <h2 className='gradient mb-3 mt-5' style={{ fontSize: 16 }}>{name} ({tokenType || "ERC721"})</h2>
                                {selec && (
                                    <div style={checkMarkStyle}>&#10003;</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default NFTCard;