/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import image_1 from "../assets/img/mobile_screen_1.png"
import image_2 from "../assets/img/mobile_screen_2.png"

const LoginMobile = () => {
  const { profileInformation, sphereOneWalletProps } = useOneTapProvider();
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const addMobileWaitlist = async () => {
    const username = sphereOneWalletProps.username || profileInformation.username
    if (username !== undefined) {
      try {
        const response = await axios.post('https://api.qorbiworld.com/v1/add-mobile-waitlist', {
          username: username
        });
        setShowMessage(true); // Show message overlay on successful response
        return response.data;
      } catch (error) {
        console.error('Error adding to mobile waitlist:', error);
        throw error;
      }
    } else {
      localStorage.setItem('closedPath', '/mobile-registration')
      navigate("/signin");
    }
  }

  return (
    <>
      <div style={{ padding: "5%" }}>
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="txt-white" style={{ fontSize: isMobile && "24px", textAlign: "center" }}>
            REGISTER TO JOIN THE WAITLIST FOR ONE TAP MOBILE
          </h2>
          <h2 className="gradient" style={{ fontSize: isMobile && "20px", textAlign: "center", zIndex: 1 }}>
            One Tap Mobile - Coming Soon
          </h2>
          <div style={{ display: "flex", alignItems: "center", flexDirection: isMobile && "column-reverse" }}>
            <video
              id="video"
              style={{ width: isMobile ? "90%" : "50%", minHeight: 400, marginTop: isMobile && "-50px" }}
              autoPlay
              controls={true}
              muted
            >
              <source src={'https://d2ceeuvjz02d1t.cloudfront.net/MOBILE_VERSION_VID.mp4'} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div style={{ zIndex: "1" }}>
              {!isMobile &&
                <p style={{ textAlign: isMobile ? "center" : "left", color: "white", fontSize: "18px", flex: "1", marginLeft: isMobile ? 0 : 15 }}>
                  All the great fun and features from our FPS game One Tap on mobile Android and iOS. One Tap Mobile is an action-packed 1v1 first-person shooter. Built for in-game rewards through our win-to-earn model.
                </p>
              }
              <button
                style={{ marginTop: 15, backgroundColor: "#CD344D", border: "none", color: "white", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "12px", cursor: "pointer", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
                className="style2"
                onClick={() => { addMobileWaitlist() }}
              >
                Join waitlist
              </button>
            </div>
          </div>
          <div style={{ zIndex: 1, marginTop: isMobile && "-50px", }}>
            <h2 className="txt-white" style={{ fontSize: isMobile && "24px", textAlign: "center", marginTop: 60 }}>
              ANDROID & IOS
            </h2>
            <h2 className="gradient" style={{ fontSize: isMobile && "20px", textAlign: "center" }}>
              ENJOY THE FUN ON ANY DEVICE
            </h2>
            <div style={{ textAlign: 'center', border: '2px solid #B51F78', padding: '10px', borderRadius: '10px', marginTop: '20px' }}>
              <img src={image_1} alt="One Tap Mobile" style={{ maxWidth: '90%', height: 'auto', margin: 'auto' }} />
            </div>
          </div>
          <div style={{ zIndex: 1 }}>
            <h2 className="txt-white" style={{ fontSize: isMobile && "24px", textAlign: "center", marginTop: 60 }}>
              MOBILE FPS GAMING
            </h2>
            <h2 className="gradient" style={{ fontSize: isMobile && "20px", textAlign: "center" }}>
              WITH PLAY-TO-EARN
            </h2>
            <div style={{ textAlign: 'center', border: '2px solid #B51F78', padding: '10px', borderRadius: '10px', marginTop: '20px' }}>
              <img src={image_2} alt="One Tap FPS" style={{ maxWidth: '90%', height: 'auto', margin: 'auto' }} />
            </div>
          </div>
          <div style={{ zIndex: 1 }}>
            <h2 className="txt-white" style={{ fontSize: isMobile && "24px", textAlign: "center", marginTop: 60 }}>
              UPCOMING PLAY TO EARN DROP
            </h2>
            <h2 className="gradient" style={{ fontSize: isMobile && "20px", textAlign: "center" }}>
              COMING SOON
            </h2>
            <button
              style={{ marginTop: 15, backgroundColor: "#CD344D", border: "none", color: "white", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "12px", cursor: "pointer", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
              className="style2"
              onClick={() => { window.location.href = "https://qorbiworld.com/qorb-collection"; }}
            >
              GET WHITELISTED
            </button>
          </div>
          {showMessage && ( // Render message
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                zIndex: 9999,
              }}
            >
              <div style={{ backgroundColor: "white", color: "#CD344D", padding: 20, borderRadius: 8, border: "2px solid #CD344D", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
                <p style={{ textTransform: "uppercase", color: "#CD344D" }}>CONGRATULATIONS, YOUR ACCOUNT HAS BEEN ADDED TO THE WAITLIST FOR THE MOBILE GAME!</p>
                <button onClick={() => setShowMessage(false)} style={{ backgroundColor: "#CD344D", border: "none", color: "white", padding: "10px 24px", textAlign: "center", textDecoration: "none", display: "inline-block", fontSize: "16px", borderRadius: "8px", cursor: "pointer", marginTop: 10, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>OK</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginMobile;
