// import { useNavigate } from "react-router-dom";

export const NewCard = ({ image, title, content, game, timeAgo, link }) => {
    // const navigate = useNavigate();
    return (
        <div className="new-card-container cursor-pointer" style={{ width: "300px" }} onClick={() => window.location.href = link}>
            <div className="new-card-image-container" style={{ backgroundImage: `url(${image})` }}>
            </div>
            <div className="flex mt-3 text-white font-medium text-left">
                <div>
                    <p style={{
                        display: `inline`
                    }}>
                        {title + " "}
                    </p>
                    -
                    <span className="text-custom-gray">
                        {" " + content}
                    </span>
                </div>
            </div>
            <div className="flex text-white font-medium bottom-section">
                <div style={{ backgroundImage: `url(${game.icon})` }} className="rounded-full w-5 h-5"></div>
                <div className="mr-2 ml-2" >
                    {game.name}
                </div>
                •
                <div className="text-custom-red ml-1" >
                    {timeAgo}
                </div>
            </div>
        </div>
    );
};