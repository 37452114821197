import React from 'react';
import pets from '../assets/img/pets.png'

const Pets = () => {
    return (
        <div className="black-bg pt-5">
            <div className="container pb-5 pt-5">
                <h2 className="gradient mb-5">ONE TAP PETS</h2>
                <div className='text-white text-left leading-8'>
                    <p>
                        The pets have a fundamental role in the game, as they give the player unique abilities that will have pivotal effects during combat.
                    </p>
                    <p>
                        To be able to have access to the pet roster in One Tap Arena, players must first acquire them in the game’s shop and select them before each round. It is important to have in mind that each pet has a unique and limited ability that can be used only once per round.
                    </p>
                    <p>
                        Pets are divided into the following groups:
                    </p>
                    <ul className='list-disc pl-10'>
                        <li><b>Flank:</b> Gives the player speed or stealth-related abilities</li>
                        <li><b>Tank:</b> Gives the player damage reduction or area control</li>
                        <li><b>Support:</b> Boosts keys stats, heals allies, or defends against enemies</li>
                        <li><b>Damage:</b> Deals high damage to enemies, acting as an offensive ally</li>

                    </ul>
                    <h2 className='text-2xl mb-5 mt-5'>FULL LIST OF PETS</h2>
                    <p>
                        One Tap plans to have 2000+ pets into the game over the next 2 years. The following list represents the current pets that are being released in the next four months.
                    </p>
                    <img src={pets} className='mt-5 mb-5' alt="table" />
                    <h2 className='text-2xl mb-5 mt-5'>COLLECTABLE ITEMS</h2>
                    <p>
                        The One Tap pets are meant to be collectable items like Pokémon cards or comic books. This is why we only have a select quantity of pets for each pet style in the game.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Pets;