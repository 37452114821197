import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 382.49 354.37"
    width={props.width ? props.width : 25}
    height={props.height ? props.height : 25}
    {...props}
  >
    <g data-name="Capa 1">
      <path d="M0 199.24c2.06-6.17 5.7-10.96 12.3-12.5 3.31-.77 6.82-.77 10.25-.95 6.04-.32 12.1-.42 18.14-.79 10.8-.67 21.58-1.6 32.38-2.18 8.53-.46 17.12-1.14 25.61-.61 6.99.44 12.19 5.64 12.72 12.5 1.08 14.19 4.85 27.34 12.32 39.38 5.6 9.03 12.45 17.16 21.57 22.7 10.34 6.27 11.68 17.89 4.93 26.29-2.49 3.1-4.31 6.73-6.52 10.06-11.46 17.34-23.03 34.6-34.37 52.02-4.1 6.29-9.37 10.35-16.96 8.83-4.06-.82-8.24-2.95-11.49-5.58-9.74-7.9-19.52-15.85-28.38-24.69-10.82-10.79-19.64-23.28-27.26-36.6-9.22-16.11-16.15-33.11-20.27-51.17-1.94-8.51-2.83-17.25-4.23-25.89-.1-.64-.49-1.23-.74-1.84v-8.98ZM178.99 1c3.68-.29 7.36-.89 11.03-.81 27.54.62 54.3 5.05 79.46 16.84 4.02 1.89 8.07 3.91 11.64 6.51 5 3.64 6.38 11.72 3.26 18.02-11.95 24.12-24.06 48.17-36.14 72.23-3.34 6.65-13.6 10.6-20.71 6.88-14.2-7.43-29.54-9.31-45.23-8.14-9.27.69-18.3 3.27-26.49 7.76-7.18 3.94-17.47 1.03-21.36-6.88-12.02-24.45-24.2-48.82-36.46-73.15-3.51-6.97 1.19-16.95 7.96-20.1 13.55-6.3 27.28-11.95 42.02-14.76 10.26-1.95 20.58-3.61 30.87-5.4.05.33.11.66.16 1ZM290.55 182.1c9.56.45 20.05.89 30.53 1.45 7.62.41 15.24.92 22.85 1.49 9.04.68 18.12 1.15 27.1 2.34 6.58.87 11.61 7.51 11.46 14.12-.49 21.72-5.65 42.46-13.59 62.54-6.05 15.3-14.18 29.53-24.09 42.6-9.25 12.19-19.48 23.6-31.82 32.88-4.95 3.72-10.01 7.31-14.85 11.18-8.02 6.4-18.47 4.08-23.96-4.3-14.31-21.87-28.77-43.64-43.11-65.49-5.37-8.19-3.14-17.71 5.68-23.77 17.85-12.28 28.91-28.92 32.72-50.28.75-4.19.56-8.63 1.84-12.63 2.33-7.3 5.86-13.15 19.25-12.13ZM143.72 192.06c-.94-26.19 21.42-48.43 47.17-48.13 27.84.33 48.24 20.79 48.43 47.79.18 26.5-20.88 46.1-44.58 47.95-29.33 2.29-52.19-23.31-51.03-47.61Z" 
      style={{
        fill: "#b7b7b7",
    }}
      />
    </g>
  </svg>
)
export default SvgComponent
