import * as React from "react"
import { SVGProps } from "react"
const CatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 194.91 182.904"
        width={props.width ? props.width : 25}
        height={props.height ? props.height : 25}
    {...props}
  >
    <path
      fill="#b7b7b7"
      d="M182.21 78.79c4.24-27.07 11.12-39.36 12.17-75.79-13.55 9-32.5 24.8-39.77 41.58 2.89 8.39 15.74 25.34 15.74 25.34s-20.67-21.64-23.84-27.45C154.88 27.8 172.56 10 192.62 0c-21.46 2.13-34.32 6.69-76.56 29.92-5.1-.53-14.29-.53-18.6-.53s-13.5 0-18.61.53C36.61 6.69 23.76 2.13 2.29 0 22.35 10 40 27.8 48.4 42.42c-3.16 5.81-23.84 27.45-23.84 27.45s12.86-17 15.75-25.34C33 27.75 14.08 12 .53 3c1.06 36.43 7.93 48.72 12.18 75.79C6.16 90.16.28 107.93 0 123.54c5.9 19 31.09 32.44 50.27 40 10.08 8.53 16.34 13.16 25.26 18.46 6.67 2.77 15.31-1.44 19.54-4.36-3.51-2-9.15-5.74-10.2-10.43 3.78-.94 9 3.33 12.59 4.62 3.61-1.29 8.8-5.56 12.58-4.62-1.05 4.69-6.69 8.45-10.2 10.43 4.24 2.92 12.88 7.13 19.54 4.36 8.92-5.34 15.18-10 25.27-18.49 19.17-7.57 44.37-21 50.26-40-.28-15.58-6.16-33.35-12.7-44.72ZM76.3 148.09l-3.3-3.7c-15 .48-22.67-2.8-28.56-5.94-10.36-5.5-11.91-26.17-5-28.19 25.2-7.36 37.08 13.47 36.83 33.17.01 1.28.03 4.66.03 4.66Zm74.17-9.64c-5.89 3.14-13.61 6.42-28.56 5.94l-3.3 3.7v-4.66c-.24-19.7 11.64-40.53 36.84-33.17 6.92 2.02 5.37 22.74-4.98 28.19Z"
      data-name="Trazado 70719"
    />
  </svg>
)
export default CatIcon

