import React, { useState } from 'react';
import { sphereoneSdk } from "../utils/SphereOne";
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import axios from 'axios';

const RegisterForm = ({ walletAddress, otidParam, removeForm, isSphereone, sphereOneId, sphereoneEmail }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { setLoginMethod, setIsEthereumConnected, setIsWalletConnected, setFilteredWallets, setProfileInformation } = useOneTapProvider();

    const handleChangeUsername = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setUsername(value);
        }
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setEmail(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isSphereone) {
            try {
                if (otidParam !== null) {
                    const response = await axios.post('https://api.qorbiworld.com/sphere-one/set-wombat-user', {
                        otid: otidParam,
                        username: username,
                        walletId: walletAddress,
                        email: email,
                    });
                    console.count(response);
                } else {
                    const response = await axios.post('https://api.qorbiworld.com/sphere-one/add-user', {
                        username: username,
                        walletId: walletAddress,
                        email: email,
                        source: "Ethereum"
                    });
                    console.count(response)
                    const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${walletAddress}`);
                    const defaultProfilePicture = `https://ui-avatars.com/api/?background=random&length=1&name=${username}`; //Image Generated from https://ui-avatars.com/#google_vignette
                    const updatedUserInfo = {
                        ...userResponse.data,
                        profilePicture: defaultProfilePicture,
                    };
                    setProfileInformation(updatedUserInfo);
                    setLoginMethod('ETH')
                    setIsEthereumConnected(true)
                    setIsWalletConnected(true)
                    setFilteredWallets([walletAddress])
                    localStorage.setItem('profileInformation', JSON.stringify(updatedUserInfo));
                    localStorage.setItem('loginMethod', 'ETH');
                    localStorage.setItem('isEthereumConnected', JSON.stringify(true));
                    localStorage.setItem('isWalletConnected', JSON.stringify(true));
                    localStorage.setItem('filteredWallets', JSON.stringify([walletAddress]));
                }

                removeForm();
            } catch (addUserError) {
                console.error('Error adding user:', addUserError);
                setErrorMessage(addUserError.response.data.message);
            }
        } else {
            try {
                const response = await axios.post('https://api.qorbiworld.com/sphere-one/add-user', {
                    username: username,
                    walletId: walletAddress,
                    email: sphereoneEmail,
                    source: "Sphereone",
                    sphereOneId: sphereOneId,
                });
                console.count(response)
                removeForm();
            } catch (addUserError) {
                console.error('Error adding user:', addUserError);
                setErrorMessage(addUserError.response.data.message);
            }
        }

    };

    const logoutSphereOne = async () => {
        if (localStorage.getItem("WALLET_ADDRESS")) {
            localStorage.removeItem("STARK_PUBLIC_KEY");
            localStorage.removeItem("WALLET_ADDRESS");
        }
        try {
            sphereoneSdk.logout();
        } catch (e) {
            console.error(e);
        }
        window.location.reload();
    };

    return (
        <div className="main-overlay">
            <div className='black-overlay' style={{ width: !isMobile && "30%" }}>
                <h2 style={{ color: "white", fontSize: 35 }}>Create Your Account</h2>
                {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
                <label htmlFor="username" style={{ color: "white", marginBottom: 15 }}>This is your gamer username.</label>
                <form onSubmit={handleSubmit}>
                    <div className="register-form--group">
                        <label htmlFor="username" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Username:</label>
                        <input
                            className='register-form-input'
                            type="text"
                            id="username"
                            placeholder="Ex: CoolQorUser"
                            value={username}
                            onChange={handleChangeUsername}
                            required
                        />
                    </div>

                    {!isSphereone &&
                        <div className="register-form-group">
                            <label htmlFor="email" style={{ color: "white", display: "block", textAlign: "left", marginLeft: 10 }}>Email:</label>
                            <input
                                className='register-form-input'
                                type="email"
                                id="email"
                                placeholder="Ex: cool@qor.user"
                                value={email}
                                onChange={handleChangeEmail}
                                required
                            />
                        </div>
                    }
                    <button className='register-form-button' type="submit">Register</button>
                </form>
                <button
                    style={{ marginTop: 15, backgroundColor: "red", transition: "background-color 0.3s" }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = "#B80000"}
                    onMouseLeave={(e) => e.target.style.backgroundColor = "red"}
                    className='register-form-button'
                    onClick={() => {
                        logoutSphereOne();
                    }}>
                    Cancel Login
                </button>
            </div>
        </div>
    );
};

export default RegisterForm;
