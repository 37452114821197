import React from 'react';
import { useParams } from 'react-router-dom';
import { marketItems } from "../utils/MarketItems"

const SuccessPage = () => {
  const params = useParams()

  return (
    <div className="black-bg pt-5">
      <div className="container pb-5 pt-5">
        <h2 className="gradient mb-5">{params.status}</h2>
        <div className='text-white text-left'>
          <div className='bg-box' style={{ width: "50%", margin: "0 auto", maxWidth: "100%" }}>
            <div className='bg-cover bg-center m-auto rounded-lg' style={{ backgroundImage: `url(${marketItems[params.item].img})` }} />
            <h2 className='gradient mb-3 mt-5' style={{ fontSize: 16 }}>{marketItems[params.item].name}</h2>
            <p className='text-white mb-5' >{marketItems[params.item].desc}</p>
            <span className='text-custom-red2'>${marketItems[params.item].price}.00</span>
            <p className='text-white text-sm text-right'>{params.status === "success" ? "Your item will bee added to your in-game wallet in a few minutes." : "Something went wrong. Please try again"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
