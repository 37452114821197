import * as React from "react"
import { SVGProps } from "react"
const MapIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 2"
        viewBox="0 0 335.48 263.53"
        width={props.width ? props.width : 20}
        height={props.height ? props.height : 20}
        {...props}
    >
        <g data-name="Capa 1">
            <path d="M95.9 261.14c-7.67-1.73-14.88-3.22-22.02-4.98-20.81-5.14-41.57-10.51-62.41-15.53C4.3 238.9-.02 234.8 0 226.29.16 154.96.15 83.62 0 12.28-.02 4.36 6.04-1.69 15.05.43c16.41 3.86 32.67 8.36 49 12.57 9.8 2.52 19.62 4.93 29.38 7.57 1 .27 1.69 1.77 2.44 2.77.18.24.03.73.03 1.1V261.14ZM239.79 3.2c10.69 2.61 21.22 5.16 31.74 7.74 16.98 4.17 33.92 8.53 50.96 12.46 9.27 2.14 13 6.4 12.99 16.23-.08 69.98-.05 139.95-.04 209.93 0 6.41-2.26 11.7-8.77 13.4-3.45.9-7.54.64-11.07-.21-19.55-4.72-39.02-9.75-58.5-14.74-5.72-1.47-11.4-3.14-17.3-4.77V3.2ZM215.67 2.91v234.41c0 5.45-.04 5.25-5.18 6.27-19.67 3.89-39.3 7.95-58.96 11.86-10.43 2.08-20.88 3.99-31.5 6.01V22.03c31.69-6.34 63.47-12.69 95.64-19.12Z" />
        </g>
    </svg>
)
export default MapIcon
