import React from 'react';

const ErrorShowing = ({ errorText, backColor }) => {
  const backgroundColor = backColor || '#F44336';

  return (
    <div style={{
      padding: '20px',
      backgroundColor: backgroundColor,
      color: 'white',
      fontSize: '16px',
      textAlign: 'center',
    }}>
      {errorText}
    </div>
  );
};

export default ErrorShowing;