import * as React from "react"
import { SVGProps } from "react"
const PetIcon2 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.237 16.706"
        width={props.width ? props.width : 19.237}
        height={props.height ? props.height : 16.706}
        {...props}
    >
        <g data-name="Capa 1">
            <g data-name="Grupo 17332">
                <path
                    d="M9.606 7.794a3.771 3.771 0 0 1 2.379.755 2.483 2.483 0 0 1 .283.233c.4.38.79.766 1.188 1.146.537.512 1.068 1.03 1.617 1.529a4.216 4.216 0 0 1 1.053 1.184 2.737 2.737 0 0 1 .068 2.307 2.7 2.7 0 0 1-1.663 1.608 2.6 2.6 0 0 1-1.531.049c-.72-.177-1.434-.391-2.164-.508a8.2 8.2 0 0 0-1.819-.082 10.622 10.622 0 0 0-1.508.318c-.515.112-1.029.229-1.547.327a2.462 2.462 0 0 1-2.034-.536 2.805 2.805 0 0 1-.99-1.517 2.539 2.539 0 0 1 .373-2.255 7.645 7.645 0 0 1 .9-.927c.661-.641 1.325-1.281 2-1.91a12.6 12.6 0 0 1 1.2-1.052 3.4 3.4 0 0 1 2.2-.67Z"
                    data-name="Trazado 70711"
                />
                <path
                    d="M4.453 3.022a3.8 3.8 0 0 1 .431-1.748A2.2 2.2 0 0 1 6.048.14a1.79 1.79 0 0 1 1.8.252A2.753 2.753 0 0 1 8.89 2.221a4.974 4.974 0 0 1 .118 1.106 3.47 3.47 0 0 1-1.06 2.532 1.8 1.8 0 0 1-1.158.491 1.864 1.864 0 0 1-1.662-.927 3.9 3.9 0 0 1-.672-2.4Z"
                    data-name="Trazado 70712"
                />
                <path
                    d="M14.783 2.877a4.282 4.282 0 0 1-.2 1.667 2.767 2.767 0 0 1-1.265 1.615 1.6 1.6 0 0 1-1.841-.158 3.055 3.055 0 0 1-1.123-1.883 3.545 3.545 0 0 1-.123-.919 4.064 4.064 0 0 1 .711-2.351 1.875 1.875 0 0 1 1.694-.842 2.056 2.056 0 0 1 1.634 1.095 3.682 3.682 0 0 1 .513 1.779Z"
                    data-name="Trazado 70713"
                />
                <path
                    d="M0 6.55a2.88 2.88 0 0 1 .351-1.419 1.461 1.461 0 0 1 2.252-.5 3.231 3.231 0 0 1 1.27 2.287 5.446 5.446 0 0 1 .04 1.189 1.9 1.9 0 0 1-1.232 1.775 1.458 1.458 0 0 1-1.439-.356 3.563 3.563 0 0 1-1.13-2.091C.05 7.145.036 6.845 0 6.55Z"
                    data-name="Trazado 70714"
                />
                <path
                    d="M15.287 7.652a4.234 4.234 0 0 1 .655-2.245 2.118 2.118 0 0 1 1.355-1.066 1.338 1.338 0 0 1 1.186.3 2.131 2.131 0 0 1 .7 1.354 3.976 3.976 0 0 1-.818 3.2 1.624 1.624 0 0 1-1.829.667 1.682 1.682 0 0 1-1.122-1.341 7.69 7.69 0 0 1-.127-.869Z"
                    data-name="Trazado 70715"
                />
            </g>
        </g>
    </svg>
)
export default PetIcon2
