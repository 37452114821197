import React from 'react';
import IconTwitter from "../assets/svg/twitterIcon";
import IconDiscord from "../assets/svg/discordIcon";
import IconYoutube from "../assets/svg/youtubeIcon";

const Footer = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const handleIconClick = (url) => {
        window.open(url, '_blank');
    };

    return (<>
        {
            !isMobile && <footer className="footer mt-3">
                <div className="container">
                    <p className='text-white mb-2'>Powered By Qorbi World</p>
                    <div className="social-networks">
                        <button className="footer-social-icon mr-3" onClick={() => handleIconClick('https://twitter.com/playonetap')}>
                            <IconTwitter />
                        </button>
                        <button className="footer-social-icon mr-3" onClick={() => handleIconClick('https://discord.com/invite/9Rv4nwxx5Y')}>
                            <IconDiscord />
                        </button>
                        <button className="footer-social-icon" onClick={() => handleIconClick('https://www.youtube.com/@playonetap')}>
                            <IconYoutube />
                        </button>
                    </div>
                </div>
            </footer>
        }
    </>
    );
};


export default Footer;