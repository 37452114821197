export const marketItems = [
    // {
    //   id: 0,
    //   name: "Qor-X Passes",
    //   desc: "The Qor-X Pass is the Qorbi World equivalent of the well-known Battle Pass system most FPS games utilize. With this pass in your wallet, you get access to a rewarding system that allows you to unlock rewards from staking, airdrops, achievements, and in-game content across the entire Qorbi World gaming ecosystem.",
    //   price: 25,
    //   url: "https://buy.stripe.com/28o5ntbTRdCUgMg8wy?client_reference_id=",
    //   moreUrl: "",
    //   img: "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v2.jpg"
    // },
    {
      id: 1,
      name: "One Tap Loot Box",
      desc: "Each One Tap Loot box has a mystery item inside. Each item is an in-game skin used to add more fun to your gameplay.",
      price: 5,
      url: "https://buy.stripe.com/aEU3fl8HFaqIgMgcN9?client_reference_id=",
      moreUrl: "https://playonetap.com/lootbox",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png"
    },
    {
      id: 2,
      name: "Dog (Multiplier x4)",
      desc: "The One Tap Dog is a great friend to take into a new round. The dog gives you the ability to put up a turret.",
      price: 55, url: "https://buy.stripe.com/dR617d4rp8iA7bGeUV?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://qbaby-web-assets.s3.amazonaws.com/dog.png"
    },
    {
      id: 3,
      name: "Armadillo (Multiplier x3)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Armadillo's special ability allows it to use its shell to create a whirlpool, dealing damage to your enemy.",
      price: 65,
      url: "https://buy.stripe.com/eVabLR4rp56o9jO6or?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Armadillo.png"
    },
    {
      id: 4,
      name: "Dragon (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Dragon's special ability allows it to create a sphere of light that protects you from damage within it.",
      price: 55,
      url: "https://buy.stripe.com/cN217dbTR6as67C4gk?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Dragon.png"
    },
    {
      id: 5,
      name: "Pterodactyl (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Pterodactyl's special ability allows it to shield you with its wings, thus protecting you from damage caused by your enemies.",
      price: 55,
      url: "https://buy.stripe.com/00geY3f637ewfIc9AF?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Pteranodon.png"
    },
    {
      id: 6,
      name: "Sabertooth (Multiplier x3)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Sabertooth's special ability allows it to ram into your opponent, causing damage.",
      price: "55",
      url: "https://buy.stripe.com/28ocPV1fdbuM8fK006?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Sabertooth.png"
    },
    {
      id: 7,
      name: "Snake (Multiplier x4)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Snake's special ability coats your bullets in snake venom, causing an additional 2 seconds of damage per bullet impact to your opponent.",
      price: 75,
      url: "https://buy.stripe.com/bIY4jp8HFbuM7bGcMT?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/snake.png"
    },
    {
      id: 8,
      name: "Monkey (Multiplier x4)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Monkey's special ability allows it to Kung Fu kick your enemies at close range, causing damage.",
      price: 65,
      url: "https://buy.stripe.com/5kA5nt2jh7eweE84go?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Monkey.png"
    },
    {
      id: 9,
      name: "Fox (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Fox's special ability allows it to shock your enemy with an electrical charge, causing damage.",
      price: 38,
      url: "https://buy.stripe.com/eVa8zFe1Z9mEdA47sB?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Fox.png"
    },
    {
      id: 10,
      name: "Rabbit (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Rabbit's special ability allows it to jump into the air. Upon landing, it causes damage to nearby enemies within a 2-meter radius.",
      price: 45,
      url: "https://buy.stripe.com/5kA9DJ2jh7ew53y5ku?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Rabbit.png"
    },
    {
      id: 11,
      name: "Wolf (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Wolf's special ability allows it to bark a sharp pitch, stunning your opponent.",
      price: 45,
      url: "https://buy.stripe.com/bIY7vBf632YganSaEP?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Wolf.png"
    },
    {
      id: 12,
      name: "Lion (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Lion's special ability allows it to give a mighty roar, exhaling a path of flames that causes fire damage to your enemy.",
      price: 55,
      url: "https://buy.stripe.com/eVa6rx6zx8iA53y8wI?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Lion.png"
    },
    {
      id: 13,
      name: "T-Rex (Multiplier x3)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The T-Rex's special ability allows it to unleash its terrifying roar, stopping enemies in their path and causing a temporary stun.",
      price: 65,
      url: "https://buy.stripe.com/6oE4jp1fd0Q8fIccMZ?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/trex.png"
    },
    {
      id: 14,
      name: "Cat (Multiplier x3)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Cat's special ability gives you stealth, quieting your movement and giving you 150% movement speed.",
      price: 50,
      url: "https://buy.stripe.com/cN2dTZ5vt6asdA46oC?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Cat.png"
    },
    {
      id: 15,
      name: "Eagle (Multiplier x5)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Eagle's special ability utilizes its eagle-eye vision, revealing enemy positions.",
      price: 70,
      url: "https://buy.stripe.com/4gw8zF7DB1Uc53y7sH?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Eagle.png"
    },
    {
      id: 16,
      name: "Mouse (Multiplier x8)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Mouse's special ability emits a blinding flash, causing temporary disorientation of nearby enemies, leaving them vulnerable to attacks and evasions.",
      price: 75,
      url: "https://buy.stripe.com/7sI3fl4rp6as2VqeVa?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Mouse.png"
    },
    {
      id: 17,
      name: "Panther (Multiplier x4)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Panther's special ability allows it to move stealthily, allowing you to spot nearby enemies.",
      price: 65,
      url: "https://buy.stripe.com/3csbLRaPNcyQeE8eVb?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Panter.png"
    },
    {
      id: 18,
      name: "Hyena (Multiplier x5)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Hyena's special ability allows you to amplify your health when you pick up a health pack.",
      price: 70,
      url: "https://buy.stripe.com/14k17d6zx1Uc3Zu14m?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Hyena.png"
    },
    {
      id: 19,
      name: "Tiger (Multiplier x3)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Tiger's special ability gives it a mighty roar, healing you for 40 health. In team game modes, its roar also heals nearby allies.",
      price: 50,
      url: "https://buy.stripe.com/3cs5nt4rp9mEfIcaEX?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/tiger.png"
    },
    {
      id: 20,
      name: "Toad (Multiplier x2)",
      desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Toad's special ability allows it to flick its tongue out, throwing poison at the enemy.",
      price: "38",
      url: "https://buy.stripe.com/4gwbLR2jh9mE0NifZi?client_reference_id=",
      moreUrl: "https://playonetap.com/pets",
      img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/toad.png"
    }
    // {
    //   id: 0,
    //   name: "",
    //   desc: "",
    //   price: "",
    //   url: "?client_reference_id=",
    //   moreUrl: "https://playonetap.com/pets",
    //   img: ""
    // }
  ]