import React from 'react';
import bg from '../assets/img/maps/castleBG.jpg'
import WidgetIcon from '../assets/svg/WidgetIcon';
import Footer from '../components/Footer';

const Blog = () => {
    return (
        <div className='container pt-10 blog'>
            <div className='bg-center bg-cover w-full h-96 rounded-custom-border bg-img' style={{ backgroundImage: `url(${bg})` }} />
            <div className='mt-8'>
                <div className='sm:flex justify-between items-center mb-8 text-left'>
                    <div className='flex items-center sm:justify-center mb-3 sm:mb-0'>
                        <div className='bg-custom-red w-10 h-10 rounded-full flex items-center justify-center blog-icon'><WidgetIcon /></div>
                        <h4 className='text-white ml-3 text-sm tracking-widest'>One Tap Game</h4>
                    </div>
                    <h4 className='text-custom-gray ml-3 text-sm tracking-widest font-normal'><b className='text-white'>Published:</b> Jun 27, 2023</h4>
                </div>
                <h1 className='text-white font-semibold text-left text-3xl tracking-widest mb-5'>One Tap Beta Release: A New Era of Gaming is on the Horizon!</h1>
                <div className='text-white text-left desc'>
                    <p>Hello, One Tap Warriors!</p>
                    <p>We're thrilled to bring you some exciting news that's sure to ignite your gaming spirit! Get ready to experience a whole new level of gaming with the upcoming beta release of One Tap!</p>
                    <h2>A Revolution in the FPS Genre</h2>
                    <p>One Tap isn't just another first-person shooter game - it's a revolution in the FPS genre. We've combined the adrenaline-pumping action you love with a unique strategic component. You'll be able to choose from a variety of in-game assets that can significantly influence the course of the game. These assets aren't just for show - they have unique attributes and abilities that can turn the tide of a match. Your choice of asset could be just as important as your shooting skills!</p>
                    <h2>Unleash the Power of Your Assets</h2>
                    <p>For those of you who already hold an in-game asset (egg), this is your golden opportunity to put it to use in the game. We can't wait to see how you'll use these assets to dominate the battlefield. <a href="https://aqua.xyz/ot/search/pets?row=0" target="_blank" rel="noreferrer">Get your eggs and trade them in for pets today.</a></p>
                    <h2>Get a Glimpse of the Action</h2>
                    <p>Want a sneak peek of what's to come? Check out our trailer below to see One Tap in action!</p>
                    <p> <a href="https://youtu.be/8hjaHzAZKTc" target="_blank" rel="noreferrer">Check out the action.</a></p>
                    <h2>Join the Closed Beta</h2>
                    <p>The beta release of One Tap is here! It's currently open to all of our existing community members and NFT holders. But that's not all - we're doing an ongoing rolling admission to the closed beta access. So, if you're not part of the beta yet, don't worry! There's still a chance for you to join in on the action. Stay connected with the One Tap community by following us on <a href="https://twitter.com/playonetap" target="_blank" rel="noreferrer">Twitter</a> and joining our <a href="https://discord.com/invite/9Rv4nwxx5Y','blank', 'scrollbars=yes,  toolbar=no, width=700, height=500'))">HERE </a>
          to get the most up-to-date information. We're looking forward to bringing you even more thrilling gaming experiences with One Tap.</p>
                    <p>Ready to take your gaming to the next level? </p>
                    <p>Click <a href="https://form.jotform.com/231986796784178" target="_blank" rel="noreferrer">Here </a> to get access to our beta release!</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Blog;