import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useOneTapProvider } from '../utils/Globals/CustomHooks';
import backImage from "../assets/img/petsAndWeaponsBackgroundMobile.webp"
import Footer from '../components/Footer';

const Leaderboard = () => {
    const { profileInformation, sphereOneWalletProps } = useOneTapProvider();
    const { special } = useParams();
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [userLocated, setUserLocated] = useState(false);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const highlightedStyles = {
        background: 'rgba(255, 255, 255, 0.2)',
        fontWeight: 'bold',
        borderRadius: '32px',
    };

    const getLeaderboard = async () => {
        try {
            const apiUrl = `https://api.qorbiworld.com/v1/battlescore-data/${special}`;
            const response = await axios.get(apiUrl);
            if (response.status === 200) {
                setLeaderboardData(response.data.data.users);
                // setLeaderboardData(testing);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const locateCurrentUser = async () => {
        const username = sphereOneWalletProps.username || profileInformation.username || "Visitor"
        if (username === null) {
            return; // Do nothing if username is null, he needs to register in one tap to appear here
        }
        const userIndex = leaderboardData.findIndex(item => item.username === username);

        if (userIndex !== -1) {
            // User found in leaderboardData, style that row differently
            setUserLocated(true)
            const updatedLeaderboardData = [...leaderboardData];
            updatedLeaderboardData[userIndex] = {
                ...updatedLeaderboardData[userIndex],
                highlighted: true, // Add a flag to indicate the highlighted row
            };
            if (userIndex > 5) {
                const locatedUser = updatedLeaderboardData.splice(userIndex, 1)[0]; // Remove the located user from the array
                updatedLeaderboardData.splice(5, 0, locatedUser);
            }
            setLeaderboardData(updatedLeaderboardData);
        } else {
            // No match found, create a new object in leaderboardData
            const newUser = {
                username: username,
                positionRank: "Mr",
                points: "P",
                winrate: "F",
                highlighted: true, // Add a flag to indicate the highlighted row
            };
            setLeaderboardData(prevData => [newUser, ...prevData]);
        }
    };

    useEffect(() => {
        getLeaderboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!userLocated && leaderboardData.length !== 0) {
            locateCurrentUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaderboardData]);

    return (
        <div className='pets-container' style={{ color: 'white', minHeight: "85vh", fontFamily: 'SF UI Display, sans-serif', paddingTop: isMobile ? 0 : '50px', backgroundImage: isMobile ? `url(${backImage})` : undefined }}>
            <div style={{ fontSize: '40px', textAlign: 'left', marginLeft: !isMobile ? '100px' : ' 10px', paddingTop: isMobile ? '10px' : '50px', fontWeight: 'bold', fontFamily: 'Exo, ExtraBold, sans-serif' }}>
                {!isMobile ? special : "Leaderboard"}
            </div>
            {isMobile ? (
                <div style={{ margin: '50px 20px', fontWeight: 'medium', fontSize: '20px' }}>
                    {leaderboardData.map((user) => (
                        <div key={user.positionRank} className="leaderboard-mobile-card" style={{ ...highlightedStyles, marginTop: '20px', borderRadius: '25px', background: 'rgba(255, 255, 255, 0.2)', border: '1px solid #FFFFFF1A', padding: '20px', opacity: '1', backdropFilter: 'blur(15px)', WebkitBackdropFilter: 'blur(15px)' }}>
                            <div className="leaderboard-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', borderRadius: '32px', }}>
                                <div style={{ flex: '1', textAlign: 'start' }}>Rank</div>
                                <div style={{ flex: '2', textAlign: 'start' }}>Username</div>
                                <div style={{ flex: '1', textAlign: 'end', paddingRight: '15px' }}>Score</div>
                            </div>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <div style={{ flex: '1', display: 'flex' }}>
                                    <img
                                        src={`https://ui-avatars.com/api/?background=${user.highlighted ? 'D20607' : (user.positionRank <= 10 ? 'CBAF5C' : 'EDECEE')}&length=3&rounded=true&bold=true&name=${user.positionRank}`}
                                        alt={`Rank ${user.positionRank}`}
                                        style={{ width: '50px', height: '50px' }}
                                    />
                                </div>
                                <div style={{ flex: '2', display: 'flex', alignItems: 'center', color: "#A6A6A6" }}>
                                    <span>{user.username}</span>
                                </div>
                                <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '15px' }}>
                                    <div style={{ background: 'red', color: 'white', borderRadius: '10px', padding: '5px', width: 50 }}>{user.points}</div>
                                </div>
                            </div>
                            <div style={{ textAlign: 'start', paddingTop: '10px', borderTop: '1px solid #FFFFFF1A' }}>
                                Winrate: <span style={{ color: "#A6A6A6" }}>{user.winrate}%</span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{ margin: '50px 100px', fontWeight: 'medium', fontSize: '20px' }}>
                    <div className="leaderboard-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', borderRadius: '32px', background: 'rgba(255, 255, 255, 0.2)', padding: '15px' }}>
                        <div style={{ flex: '1', textAlign: 'start' }}>Rank</div>
                        <div style={{ flex: '1', textAlign: 'start' }}>Username</div>
                        <div style={{ flex: '1', textAlign: 'center' }}>Score</div>
                        <div style={{ flex: '1', textAlign: 'end', paddingRight: 25 }}>Winrate</div>
                    </div>

                    <div className="leaderboard-content">
                        {leaderboardData.map((user) => (
                            <div key={user.positionRank} className="leaderboard-row" style={{ ...(user.highlighted && highlightedStyles), marginTop: '8px', display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                                <div style={{ flex: '1', display: 'flex' }}>
                                    <img
                                        src={`https://ui-avatars.com/api/?background=${user.highlighted ? 'D20607' : (user.positionRank <= 10 ? 'CBAF5C' : 'EDECEE')}&length=3&rounded=true&bold=true&name=${user.positionRank}`}
                                        alt={`Rank ${user.positionRank}`}
                                        style={{ width: '50px', height: '50px' }}
                                    />
                                </div>
                                <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={`https://ui-avatars.com/api/?background=${user.highlighted ? 'D20607' : (user.positionRank <= 10 ? 'CBAF5C' : 'EDECEE')}&length=1&rounded=true&bold=true&name=${user.username}`}
                                        alt={`Username ${user.username}`}
                                        style={{ width: '50px', height: '50px', marginRight: '5px' }}
                                    />
                                    {user.username}
                                </div>
                                <div style={{ flex: '1', textAlign: 'center', paddingTop: '15px' }}>{user.points}</div>
                                <div style={{ flex: '1', textAlign: 'end', paddingTop: '15px', paddingRight: 25 }}>{user.winrate}%</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <Footer/>
        </div >
    );
};

export default Leaderboard;
