import React from 'react';
import IconTwitter from "../assets/svg/twitterIcon";
import IconDiscord from "../assets/svg/discordIcon";
import IconYoutube from "../assets/svg/youtubeIcon";

const FooterHome = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const handleIconClick = (url) => {
        window.open(url, '_blank');
    };
    return (<>
        {
            !isMobile && <footer className="bg-custom-gray2 w-12 py-5 rounded-full flex justify-center nav-widget absolute right-8">
                <div className="social-networks">
                    <p className='mb-3 text-white' style={{fontSize:10}}>Powered <br/>By <br/>Qorbi <br/>World</p>
                    <button className="footer-social-icon" onClick={() => handleIconClick('https://twitter.com/playonetap')}>
                        <IconTwitter />
                    </button>
                    <button className="footer-social-icon" onClick={() => handleIconClick('https://discord.com/invite/9Rv4nwxx5Y')}>
                        <IconDiscord />
                    </button>
                    <button className="footer-social-icon" onClick={() => handleIconClick('https://www.youtube.com/@playonetap')}>
                        <IconYoutube />
                    </button>
                </div>
            </footer>
        }
    </>
    );
};

export default FooterHome;