import React, { useState } from 'react';
import ErrorShowing from './ErrorShowing';
import { useOneTapProvider } from "../utils/Globals/CustomHooks";

const WalletSelectionOverlay = ({ defaultWallet, closeWalletSelector, updateSelectedWallet }) => {
    const { filteredWallets } = useOneTapProvider();
    const [selectedWallet, setSelectedWallet] = useState(defaultWallet);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleWalletSelection = async () => {
        if (selectedWallet) {
            updateSelectedWallet(selectedWallet);
            closeWalletSelector();
        } else {
            setErrorMsg('Please select a wallet.');
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.7)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            {errorMsg && <ErrorShowing errorText={errorMsg} />}
            <div
                style={{
                    background: 'black',
                    padding: '20px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                }}
            >
                <p style={{ fontSize: '24px', color: 'white' }}>We noticed you have more than one IMX wallet</p>
                <p style={{ fontSize: '16px', color: 'grey' }}>Choose one to make this transaction</p>
                <select
                    value={selectedWallet ? selectedWallet.address : ""}
                    onChange={(e) => {
                        const selectedAddress = e.target.value;
                        const selected = filteredWallets.find(wallet => wallet.address === selectedAddress);
                        setSelectedWallet(selected);
                    }}
                    style={{
                        width: '100%',
                        padding: '10px',
                        margin: '10px 0',
                        border: '2px solid #AE1985',
                        borderRadius: '5px',
                        fontSize: '16px',
                    }}
                >
                    <option value="" disabled>Select a Wallet</option>
                    {filteredWallets.map((wallet) => (
                        <option key={wallet.address} value={wallet.address}>
                            {!wallet.isImported ? "Main In-Game" : wallet.label || "Secondary"} - {wallet.address}
                        </option>
                    ))}
                </select>
                <button
                    onClick={handleWalletSelection}
                    style={{
                        backgroundColor: '#AE1985',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '10px 20px',
                        fontSize: '18px',
                        cursor: 'pointer',
                    }}
                >
                    Select Wallet
                </button>
            </div>
        </div>
    );
};

export default WalletSelectionOverlay;