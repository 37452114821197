import React, { createContext, useContext, useState } from "react";

const baseData = {
  loginMethod: "Null",
  setLoginMethod: (newValue) => { },
  isSphereOneConnected: false,
  setIsSphereOneConnected: (newValue) => { },
  isEthereumConnected: false,
  setIsEthereumConnected: (newValue) => { },
  isPlayFabConnected: false,
  setIsPlayFabConnected: (newValue) => { },
  isWalletConnected: false,
  setIsWalletConnected: (newValue) => { },
  sphereOneWalletProps: {},
  setSphereOneWalletProps: (newValue) => { },
  filteredWallets: {},
  setFilteredWallets: (newValue) => { },
  profileInformation: {},
  setProfileInformation: (newValue) => { },
};
const OneTapContext = createContext(baseData);

const OneTapProvider = ({ children }) => {
  const [loginMethod, setLoginMethod] = useState(
    baseData.loginMethod
  );
  const [isSphereOneConnected, setIsSphereOneConnected] = useState(
    baseData.isSphereOneConnected
  );
  const [isEthereumConnected, setIsEthereumConnected] = useState(
    baseData.setIsEthereum
  );
  const [isPlayFabConnected, setIsPlayFabConnected] = useState(
    baseData.isPlayFabConnected
  );
  const [sphereOneWalletProps, setSphereOneWalletProps] = useState(
    baseData.sphereOneWalletProps
  );
  const [isWalletConnected, setIsWalletConnected] = useState(
    baseData.isWalletConnected
  );
  const [filteredWallets, setFilteredWallets] = useState(
    baseData.filteredWallets
  );
  const [profileInformation, setProfileInformation] = useState(
    baseData.filteredWallets
  );

  return (
    <OneTapContext.Provider
      value={{
        loginMethod,
        isSphereOneConnected,
        isEthereumConnected,
        isPlayFabConnected,
        sphereOneWalletProps,
        isWalletConnected,
        filteredWallets,
        profileInformation,
        setLoginMethod,
        setIsSphereOneConnected,
        setIsEthereumConnected,
        setIsPlayFabConnected,
        setSphereOneWalletProps,
        setIsWalletConnected,
        setFilteredWallets,
        setProfileInformation,
      }}
    >
      {children}
    </OneTapContext.Provider>
  );
};

export default OneTapProvider;

export const useOneTapProvider = () => useContext(OneTapContext);
