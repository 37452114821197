import * as React from "react"
import { SVGProps } from "react"
const DogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 194.96 285.286"
        width={props.width ? props.width : 25}
        height={props.height ? props.height : 25}
    {...props}
  >
    <g data-name="Grupo 17162">
      <path
        fill="#b7b7b7"
        d="M181.9 140.656c-8.47-15.8-19.72-30-30.38-44.35-.52.19 0-22.74-.4-32.78l4.06 29.64c11.22 13 23.55 32 23.64 31.95-.82-41.22-10.77-85.13-24.27-124.08-5.76-9.88-27.11 54-29 59.5-3.39 11-6.84 17.5-7 18.7-3.24-1.07-10.32-3-21.1-2.73-10.78-.27-17.87 1.66-21.11 2.73-.2-1.2-3.65-7.68-7-18.7-1.84-5.53-23.19-69.38-28.95-59.5-13.52 38.95-23.39 82.86-24.3 124.09.09 0 12.42-18.93 23.65-31.95 1.32-9.7 4.06-29.64 4.06-29.64-.43 10 .11 33-.4 32.78-10.67 14.35-21.91 28.54-30.4 44.34-5.45 15.33-8.93 31.42-13 47.16 7.18 19.89 30 33.84 48.74 43.85l2.53-18.59c1.43 14.48.4 27.55.55 41.31.27 4.17-.87 9 2 12.48 2.34 3.47 5.68 5.79 9 8.28 5.71 4.12 10.93 9.19 18.2 10.14 8.26-3.61 12.37-5.42 16.46-5.42s8.19 1.81 16.45 5.42c7.27-.95 12.49-6 18.21-10.14 3.27-2.49 6.61-4.81 9-8.28 2.88-3.53 1.74-8.31 2-12.48.15-13.76-.88-26.83.56-41.31l2.53 18.59c18.73-10 41.56-24 48.73-43.85-4.12-15.74-7.6-31.83-13.06-47.16Zm-115.47 42.81c-2.5.33-13.79-1-17.19-4.44-5.23-5.28-4.4-9.22-4.4-12.51-1.34-.74-4.44-2.08-4.44-2.08 5.22-2 23.19-3.75 26.23 1.77a173.32 173.32 0 0 1 4.11 22.33 36 36 0 0 0-4.31-5.07Zm49 68.2c-.9 1-2.07-.27-3.1-.42-3.81-1.36-7.35 2.58-7.57 6.2-.14 1 .17 2.64-.82 3.2s-4.2 2.79-6.45 2.91c-2.26-.12-5.58-2.42-6.46-2.91s-.68-2.16-.82-3.2c-.21-3.62-3.76-7.56-7.57-6.2-1 .15-2.2 1.37-3.09.42a2.28 2.28 0 0 1-.85-2.4c.4-2.17 5.69-11.07 7.29-12.55 1-.89 1.78-1.07 3.72-.88 2.57.18 5.21.29 7.78.29s5.2-.11 7.77-.29c2-.19 2.76 0 3.72.88 1.61 1.48 6.89 10.38 7.29 12.55a2.3 2.3 0 0 1-.87 2.4Zm30.24-72.68c-3.4 3.43-14.68 4.77-17.19 4.44a35.411 35.411 0 0 0-4.3 5.07 171.621 171.621 0 0 1 4.11-22.33c3-5.52 21-3.78 26.22-1.77 0 0-3.1 1.34-4.44 2.08 0 3.33.83 7.27-4.4 12.51Z"
        data-name="Trazado 70720"
      />
    </g>
  </svg>
)
export default DogIcon
