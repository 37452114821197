import React, { useEffect, useState } from 'react';
import './App.scss';
import Routes from './routes/routes';
import { sphereoneSdk } from './utils/SphereOne';
import { useOneTapProvider } from './utils/Globals/CustomHooks';
import Logo from './assets/svg/Logo';
import axios from 'axios';
import { SupportedChains } from 'websdk';
import RegisterWalletOverlay from './components/RegisterWalletOverlay';
import { Link } from "@imtbl/imx-sdk";

function App() {

  const { setLoginMethod, setSphereOneWalletProps, setIsSphereOneConnected, setIsWalletConnected, setFilteredWallets, setProfileInformation, setIsEthereumConnected } = useOneTapProvider();
  const [loading, setLoading] = useState(true)
  const [registerWallet, setRegisterWallet] = useState({ show: false, wallet: "" });

  const checkUser = async (uid: string, email: any): Promise<string | null> => {
    const apiUrl = `https://api.qorbiworld.com/sphere-one/user/sphereone/${uid}`;
    try {
      const res = await axios.get(apiUrl);
      return res.data.username;
    } catch (error) {
      console.error(error);

      const getDataByEmail = async (): Promise<string | null> => {
        try {
          const emailUserData = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${email}`);
          await axios.patch('https://api.qorbiworld.com/sphere-one/update-user', {
            username: emailUserData.data.username,
            sphereOneId: uid
          });
          return emailUserData.data.username;
        } catch (error) {
          console.error(error);
          return null;
        }
      };
      return await getDataByEmail();
    }
  };

  const connectImmutable = async () => {
    if (localStorage.getItem("WALLET_ADDRESS")) {
      localStorage.removeItem("STARK_PUBLIC_KEY");
      localStorage.removeItem("WALLET_ADDRESS");
    }
    try {
      const link = new Link("https://link.x.immutable.com");
      if ("ethereum" in window) {
        const { address, starkPublicKey } = await link.setup({
          providerPreference: undefined,
        });
        if (address && starkPublicKey) {
          setRegisterWallet({ show: false, wallet: "" })
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loginSphereOne = async () => {
    await sphereoneSdk
      .handleCallback()
      .then(async (authResult: any) => {
        if (authResult && authResult.access_token) {
          // let walletsSO
          await sphereoneSdk.getWallets({ forceRefresh: true }).then(async walletsSO => {
            if (walletsSO.length === 0) {
              setTimeout(() => {
                window.location.reload();
              }, 7500);
            }
            authResult.wallets = walletsSO
            const userInfo = await sphereoneSdk.getUserInfo({ forceRefresh: false });
            const defaultProfilePicture = `https://ui-avatars.com/api/?background=random&length=1&name=${userInfo.name}`; //Image Generated from https://ui-avatars.com/#google_vignette
            const updatedUserInfo = {
              ...userInfo,
              profilePicture: userInfo.profilePicture || defaultProfilePicture,
            };
            setProfileInformation(updatedUserInfo);
            let username = await checkUser(userInfo.uid, userInfo.email)
            console.log(username);

            if (username != null) {
              authResult.username = username
            }
            setSphereOneWalletProps(authResult)
            const immutableWallets = walletsSO.filter(
              wallet => wallet.chains.includes(SupportedChains.IMMUTABLE)
            )
            setFilteredWallets(immutableWallets)
            if (immutableWallets.length > 1) {
              const loginMethodWallet = immutableWallets.find(wallet => 'label' in wallet && wallet.label === "Login Wallet" && wallet.isImported === true);
              if (loginMethodWallet) {
                await axios.get(`https://api.x.immutable.com/v1/users/${loginMethodWallet.address}`)
                  .then(response => {
                    //Nothing here, the wallet is registered
                  })
                  .catch(error => {
                    //Here, he needs to register into IMX
                    setRegisterWallet({ show: true, wallet: loginMethodWallet.address })
                  });
              }
            }
            setLoginMethod('SO')
            setIsSphereOneConnected(true)
            setIsWalletConnected(true)

          })
            .catch(e => {
              console.error('este es el error', e)
            });
        }
      })
      .catch((e: Error) => {
        console.error("Login Into SphereOne Failed", e);
      });
    setLoading(false)
  };

  useEffect(() => {
    loginSphereOne();

    const loginMethod = localStorage.getItem('loginMethod');
    if (loginMethod === "ETH") {
      const profileInformationString = localStorage.getItem('profileInformation');
      const isEthereumConnectedString = localStorage.getItem('isEthereumConnected');
      const isWalletConnectedString = localStorage.getItem('isWalletConnected');
      const filteredWalletsString = localStorage.getItem('filteredWallets');
      if (profileInformationString && isEthereumConnectedString && isWalletConnectedString && filteredWalletsString) {
        const profileInformation = JSON.parse(profileInformationString);
        const isEthereumConnected = JSON.parse(isEthereumConnectedString);
        const isWalletConnected = JSON.parse(isWalletConnectedString);
        const filteredWallets = JSON.parse(filteredWalletsString);
        setProfileInformation(profileInformation);
        setLoginMethod(loginMethod);
        setIsEthereumConnected(isEthereumConnected);
        setIsWalletConnected(isWalletConnected);
        setFilteredWallets(filteredWallets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App bg-black">
      {loading ?
        <div className='flex fixed justify-center items-center w-full h-full'><Logo width={150} /></div>
        : <>
          <Routes ></Routes>
          {registerWallet.show && <RegisterWalletOverlay registerImmutable={connectImmutable} unregisteredWallet={registerWallet.wallet}></RegisterWalletOverlay>}
        </>
      }
    </div>
  );
}

export default App;