import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOneTapProvider } from "../utils/Globals/CustomHooks";
import WalletSelectionOverlay from "../components/WalletSelectionOverlay";
import { sphereoneSdk } from "../utils/SphereOne";
import { SupportedChains } from "websdk";
import { marketItems } from "../utils/MarketItems"

const Shop = () => {
  const navigation = useNavigate();
  const { sphereOneWalletProps, filteredWallets, isSphereOneConnected, isEthereumConnected, profileInformation } = useOneTapProvider();
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [multipleWallets, setMultipleWallets] = useState(null)
  const [paymentLink, setPaymentLink] = useState("");

  const usernameShopper = profileInformation.username || sphereOneWalletProps.username

  const handleCloseOverlay = () => {
    setMultipleWallets(false)
  }

  const handleUpdateWallet = (newWallet) => {
    setSelectedWallet(newWallet);
  }

  const sphereOneCheckout = async (itemName, itemImage, itemAmount, itemQuantity, itemCode) => {
    try {
      const charge = await sphereoneSdk.createCharge(
        {
          chargeData: {
            symbol: "USDC",
            chain: SupportedChains.POLYGON,
            successUrl: `https://playonetap.com/transaction-response/success/${itemCode}`,
            cancelUrl: `https://playonetap.com/transaction-response/failed/${itemCode}`,
            tokenAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",//I understand this is the token address for the USDC, needs confirmation
            items: [{
              name: itemName,
              image: itemImage,
              amount: itemAmount,
              quantity: itemQuantity
            }]
          }
        }
      );
      setPaymentLink(charge.paymentUrl);
    } catch (e) {
      console.error(e);
    }
  }

  const buyItem = (stripeUrl, itemId) => {
    const username = usernameShopper || "QorbiUser"
    const referralID = "";
    try {
      window.open(
        stripeUrl + `${selectedWallet?.address}${username}${referralID}`,
        "_blank",
        "data-rewardful"
      )
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    if (paymentLink) {
      window.open(
        paymentLink,
        "_blank",
        "rel=noopener noreferrer"
      )
    }
  }, [paymentLink])

  useEffect(() => {
    localStorage.removeItem("STARK_PUBLIC_KEY");
    localStorage.removeItem("WALLET_ADDRESS");
    if (isSphereOneConnected) {
      setSelectedWallet(filteredWallets.filter(wallet => wallet.isImported === false)[0])
      setMultipleWallets(filteredWallets.length > 1)
    } else if (isEthereumConnected) {
      setSelectedWallet({ address: filteredWallets[0] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="black-bg pt-5">
      {multipleWallets && <WalletSelectionOverlay
        defaultWallet={selectedWallet}
        closeWalletSelector={handleCloseOverlay}
        updateSelectedWallet={handleUpdateWallet}
      />}
      <div className="container pb-5 pt-5">
        <h2 className="gradient mb-5">Shop One Tap</h2>
        <div className="grid lg:grid-cols-2 gap-4">
          {marketItems.map((item, index) => (
            <div className="text-left p-5" key={index}>
              <div className="bg-box" style={{ display: "grid" }}>
                <div
                  className="bg-cover bg-center m-auto rounded-lg"
                  style={{ backgroundImage: `url(${item.img})` }}
                />
                <h2 className="gradient mb-3 mt-5" style={{ fontSize: 16 }}>
                  {item.name}
                </h2>
                <p className="text-white mb-5">
                  {item.desc}
                </p>
                <span className="text-custom-red2" style={{ alignSelf: "end" }}>${item.price}.00</span>
                <div className="md:flex lg:justify-start justify-center mt-5" style={{ alignSelf: "end" }}>
                  {item.moreUrl !== "" &&
                    <a
                      href={item.moreUrl}
                      target="blank"
                    >
                      <button className="flex items-center justify-center style1 mr-5">Learn More</button>
                    </a>
                  }
                  {selectedWallet ? (
                    <button
                      className="flex items-center justify-center style2 mr-5" style={{ marginBottom: "10px" }}
                      onClick={() =>
                        buyItem(item.url, item.id)
                      }
                    >
                      Buy Now
                    </button>
                  ) : (
                    <button
                      className="flex items-center justify-center style2 mr-5" style={{ marginBottom: "10px" }}
                      onClick={() => navigation("/signin")}
                    >
                      Connect Wallet
                    </button>
                  )}
                  {false ? (
                    <button
                      className="flex items-center justify-center style2 mr-5"
                      onClick={() =>
                        sphereOneCheckout(item.name, item.img, item.price, 1, item.id)
                      }
                    >
                      Buy Using Cripto
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
          {/* <div className="text-left p-5">
            <div className="bg-box">
              <div
                className="bg-cover bg-center m-auto rounded-lg"
                style={{ backgroundImage: `url(${treasure})` }}
              />
              <h2 className="gradient mb-3 mt-5" style={{ fontSize: 16 }}>
                One Tap Loot Box
              </h2>
              <p className="text-white mb-5">
                Each One Tap Loot box has a mystery item inside. Each item
                is an in-game skin used to add more fun to your gameplay.
              </p>
              <span className="text-custom-red2">$10.00</span>
              <div className="md:flex lg:justify-start justify-center mt-5">
                <button
                  className="flex items-center justify-center style1 mr-5"
                  onClick={() => navigation("/lootbox")}
                >
                  Learn More
                </button>
                {selectedWallet ? (
                  <button
                    className="flex items-center justify-center style2 mr-5"
                    onClick={() =>
                      window.open(
                        `https://buy.stripe.com/cN25nt3nl6as8fKaEE?client_reference_id=${selectedWallet?.address}${usernameShopper}`,
                        "_blank",
                        "rel=noopener noreferrer"
                      )
                    }
                  >
                    Purchase Now
                  </button>
                ) : (
                  <button
                    className="flex items-center justify-center style2 mr-5"
                    onClick={() => navigation("/signin")}
                  >
                    Connect Wallet
                  </button>
                )}
                {false ? (
                  <button
                    className="flex items-center justify-center style2"
                    onClick={() =>
                      sphereOneCheckout("One Tap Treasure Box", "https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png", 10, 1, 'lb')
                    }
                  >
                    Purchase Using Cripto
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="text-left p-5">
            <div className="bg-box">
              <div
                className="bg-cover bg-center m-auto rounded-lg"
                style={{ backgroundImage: `url(${dog})` }}
              />
              <h2 className="gradient mb-3 mt-5" style={{ fontSize: 16 }}>
                Dog (Pet) - Coming Soon
              </h2>
              <p className="text-white mb-5">
                The One Tap Dog is a great friend to take into a new round. The
                dog gives you an ability to put up a turret which will prevent
                your opponent from flanking your position. There are only 1500
                Dogs available.
              </p>
              <span className="text-custom-red2">$55.00</span>
              <div className="md:flex lg:justify-start justify-center mt-5">
                <button
                  className="flex items-center justify-center style1 mr-5"
                  onClick={() => navigation("/pets")}
                >
                  Learn More
                </button>
                {selectedWallet ? (
                  <button
                    className="flex items-center justify-center style2 mr-5"
                    onClick={() =>
                      window.open(
                        `https://buy.stripe.com/dR617d4rp8iA7bGeUV?client_reference_id=${selectedWallet?.address}${usernameShopper}`,
                        "_blank",
                        "rel=noopener noreferrer"
                      )
                    }
                  >
                    Purchase Now
                  </button>
                ) : (
                  <button
                    className="flex items-center justify-center style2 mr-5"
                    onClick={() => navigation("/signin")}
                  >
                    Connect Wallet
                  </button>
                )}
                {false ? (
                  <button
                    className="flex items-center justify-center style2"
                    onClick={() =>
                      sphereOneCheckout("Dog (Pet)", "https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png", 55, 1, 'dg')
                    }
                  >
                    Purchase Using Cripto
                  </button>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div >
  );
};

export default Shop;
